import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, unref as _unref, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, vModelText as _vModelText, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "main_c" }
const _hoisted_2 = { class: "down_load" }
const _hoisted_3 = ["src"]
const _hoisted_4 = { class: "form" }
const _hoisted_5 = { class: "desc" }
const _hoisted_6 = ["placeholder"]
const _hoisted_7 = ["placeholder"]

import PopularizeTop from '@/components/NewTop/index.vue';
import { lang } from '@/lang/index';
import IconUrl from '@/assets/img/newHome/icon.png';
import { onMounted, reactive } from 'vue';
import ScrollReveal from 'scrollreveal';
import message from '@/utils/message';

export default /*@__PURE__*/_defineComponent({
  __name: 'index',
  setup(__props) {

const form = reactive({ email: '', desc: '' });

onMounted(() => {
  const sr = ScrollReveal({
    distance: '50px',
    duration: 1000,
    easing: 'ease-in-out',
    interval: 200,
    reset: true, // 每次进入视口时都触发
  });

  // 配置动画
  sr.reveal('.down_load', { origin: 'top' });
});
//邮箱正则
const emailReg = /\w+([-+.]\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*/;
const submit = () => {
  if (form.email === '' || form.desc === '') {
    message.error(lang.v.FormErr);
    return;
  }
  if (!emailReg.test(form.email)) {
    message.error(lang.v.EmailErr);
    return;
  }
  if (form.desc.length < 10) {
    message.error(lang.v.FormDesc);
    return;
  }
  message.success(lang.v.sendSuccess);
  form.email = '';
  form.desc = '';
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(PopularizeTop, { type: "1" }),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("img", {
        src: _unref(IconUrl),
        alt: "no data",
        class: "icon_img"
      }, null, 8, _hoisted_3),
      _cache[2] || (_cache[2] = _createElementVNode("h2", { class: "title" }, "MovSee", -1)),
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("span", _hoisted_5, _toDisplayString(_unref(lang).v.ContactDesc), 1),
        _withDirectives(_createElementVNode("input", {
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((form.email) = $event)),
          type: "text",
          placeholder: _unref(lang).v.server_email,
          class: "email_input"
        }, null, 8, _hoisted_6), [
          [_vModelText, form.email]
        ]),
        _withDirectives(_createElementVNode("textarea", {
          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((form.desc) = $event)),
          class: "textarea",
          rows: "7",
          name: "text",
          id: "",
          placeholder: _unref(lang).v.Message
        }, null, 8, _hoisted_7), [
          [_vModelText, form.desc]
        ]),
        _createElementVNode("button", {
          class: "submit",
          onClick: submit
        }, _toDisplayString(_unref(lang).v.Submit), 1)
      ])
    ])
  ]))
}
}

})